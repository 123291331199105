body {
  font-family: 'Montserrat', sans-serif !important; }
  body p {
    font-family: 'Montserrat', sans-serif !important; }
  body span {
    font-family: 'Montserrat', sans-serif !important; }
  body label {
    font-family: 'Montserrat', sans-serif !important; }
  body button {
    font-family: 'Montserrat', sans-serif !important; }

.is-sticky .inner-header {
  position: absolute !important; }

.hero-slider .single-slide .hero-content-one {
  height: 60%; }

.hero-slider .single-slide label {
  color: #135056;
  line-height: 120%;
  font-size: 1.4rem;
  margin: 20px 0 10px; }

.hero-slider .single-slide h1 {
  color: #135056;
  font-family: 'Bitter';
  text-transform: initial;
  font-size: 58px;
  line-height: 59px; }
  .hero-slider .single-slide h1 span {
    color: #E0B042;
    font-family: 'Bitter' !important; }

.hero-slider .single-slide button {
  border-radius: 30px;
  border: 0;
  background: #E0B042;
  color: #fff;
  padding: 13px 70px;
  font-size: 16px; }

.hero-slider .single-slide.slider-mockup::after {
  background: url(../img/header.jpg);
  background-size: cover !important;
  right: 0 !important; }

.about-area-one {
  background: #135056;
  padding-top: 35px;
  margin-top: 0;
  padding-bottom: 35px; }
  .about-area-one h2 {
    font-family: 'Bitter';
    color: #fff;
    text-transform: initial; }
  .about-area-one p {
    color: #fff; }
  .about-area-one .box-form {
    background: #FFFFFF;
    padding: 85px 35px;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    transform: translateY(-70px); }
    .about-area-one .box-form h5 {
      font-family: 'Montserrat';
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 100%;
      color: #0E393F;
      margin-bottom: 45px; }
  .about-area-one::after {
    display: none; }

.video-area {
  height: auto;
  background: url(../img/video-area.jpg);
  background-size: cover !important;
  padding: 7rem 0; }
  .video-area .video-info {
    margin-bottom: 0; }

.gallery-area {
  background: #D9E4DB;
  padding: 7rem 0; }
  .gallery-area h3 {
    color: #135056;
    font-family: 'Bitter';
    font-size: 1.8rem;
    font-weight: 800; }
  .gallery-area p {
    color: #135056;
    font-size: 1rem; }
  .gallery-area .slider {
    position: relative; }
    .gallery-area .slider .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: -5%;
      width: 110%;
      right: 0;
      height: 100%; }
      .gallery-area .slider .nav p {
        cursor: pointer; }
        .gallery-area .slider .nav p svg {
          width: 20px;
          height: 20px; }
  .gallery-area .slider-nav {
    margin-top: 8px; }
    .gallery-area .slider-nav img {
      width: 95%; }
  .gallery-area .icons .line {
    display: flex;
    align-items: center;
    margin: 8px 0; }
    .gallery-area .icons .line .icon img {
      width: 65px;
      margin-right: 20px; }
    .gallery-area .icons .line .text {
      width: 100%;
      color: #135056;
      font-size: 1rem; }

.whatsapp {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9;
  width: 43px; }

.icons-area {
  background: #14454A;
  padding: 7rem 0; }
  .icons-area h3 {
    color: #fff;
    font-family: 'Bitter';
    font-size: 1.8rem;
    font-weight: 800; }
  .icons-area p {
    color: #fff;
    font-size: 1rem; }
  .icons-area .icons .line {
    margin: 8px 13px;
    min-height: 120px; }
    .icons-area .icons .line .icon img {
      width: 65px;
      margin-right: 20px; }
    .icons-area .icons .line .text {
      width: 100%;
      color: #fff;
      font-size: 1rem; }

.planta-area {
  background: #E0B042; }
  .planta-area .planta-active .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 25px; }
    .planta-area .planta-active .slick-dots li button {
      width: 10px;
      height: 12px;
      margin: 3px;
      border-radius: 50%;
      background: #eaeaea;
      color: #eaeaea;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1px; }
    .planta-area .planta-active .slick-dots li.slick-active button {
      background: #cccccc;
      color: #cccccc; }
  .planta-area h4 {
    color: #fff;
    font-family: 'Bitter';
    font-size: 1.8rem;
    font-weight: 800; }
  .planta-area p {
    color: #fff;
    font-size: 1rem; }

.download-area {
  background: url(../img/download-area.jpg);
  background-size: cover !important;
  position: relative; }
  .download-area h2 {
    color: #fff;
    font-family: 'Bitter';
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: initial; }
  .download-area button {
    border-radius: 30px;
    border: 0;
    background: #E0B042;
    color: #fff;
    padding: 12px 35px;
    font-size: 16px; }
  .download-area .separador {
    width: 100%;
    height: 280px;
    position: absolute;
    top: 0;
    background-image: -webkit-gradient(linear, right bottom, right top, color-stop(0, rgba(46, 169, 97, 0)), color-stop(1, #135056));
    background-image: -o-linear-gradient(top, rgba(46, 169, 97, 0) 0%, #135056 100%);
    background-image: -moz-linear-gradient(top, rgba(46, 169, 97, 0) 0%, #135056 100%);
    background-image: -webkit-linear-gradient(top, rgba(46, 169, 97, 0) 0%, #135056 100%);
    background-image: -ms-linear-gradient(top, rgba(46, 169, 97, 0) 0%, #135056 100%);
    background-image: linear-gradient(to top, rgba(46, 169, 97, 0) 0%, #135056 100%); }

.instagram-area {
  margin-bottom: -170px;
  margin-top: -375px; }
  .instagram-area .single-testimonial {
    margin: 15px; }
  .instagram-area h5 {
    color: #14454A;
    font-family: 'Bitter';
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: initial; }

.footer-area .footer-content-area {
  background: #135056;
  padding-top: 250px;
  padding-bottom: 50px;
  line-height: 120%; }
  .footer-area .footer-content-area .box-form {
    background: #FFFFFF;
    padding: 85px 35px;
    border-radius: 15px; }
  .footer-area .footer-content-area .social button {
    background: transparent;
    width: 37px;
    height: 37px;
    border: 2px #fff solid;
    border-radius: 50%; }
    .footer-area .footer-content-area .social button svg path {
      fill: #fff; }
  .footer-area .footer-content-area .contacts {
    margin-top: 25px; }
    .footer-area .footer-content-area .contacts label {
      color: #FFF;
      font-size: 15px; }
    .footer-area .footer-content-area .contacts p {
      margin-bottom: 3px; }
  .footer-area .footer-content-area h5 {
    color: #fff;
    font-family: 'Bitter';
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: initial;
    line-height: 120%; }
  .footer-area .footer-content-area p {
    color: #fff;
    line-height: 150%; }

.contact-form-area input {
  border: 0;
  border-bottom: 1px #6F6F6F solid;
  border-radius: 0; }
  .contact-form-area input::-webkit-input-placeholder {
    /* Edge */
    color: #6F6F6F; }
  .contact-form-area input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6F6F6F; }
  .contact-form-area input::placeholder {
    color: #6F6F6F; }

.contact-form-area button {
  background: #E0B042 !important;
  padding: 12px 70px !important; }
  .contact-form-area button:hover {
    background: #ebbd52 !important; }

@media (max-width: 960px) {
  .logo {
    text-align: center; }
  .hero-slider .single-slide h1 {
    font-size: 33px;
    line-height: 115%; }
    .hero-slider .single-slide h1 br {
      display: none; }
  .hero-slider .single-slide button {
    padding: 13px 40px; }
  .about-area-one .box-form {
    transform: translateY(0);
    margin-top: 35px;
    padding: 25px 25px; }
  .download-area h2 {
    font-size: 1.4rem; }
  .download-area button {
    padding: 13px 4px; }
  .instagram-area {
    margin-top: 25px; }
  .section-title.text-black h2 br {
    display: none; }
  .footer-area .footer-content-area .box-form {
    margin-top: 25px;
    padding: 35px 35px; } }

@media (min-width: 1360px) and (max-width: 1439px) {
  .hero-slider .single-slide {
    height: 766px; }
    .hero-slider .single-slide img {
      width: 75%; }
    .hero-slider .single-slide label {
      font-size: 1rem; }
  .hero-slider h1 {
    font-size: 35px;
    line-height: 39px; } }
